import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { ContentPane } from "../../components/ContentPane.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { NavigateAfterDelete } from "../../components/NavigateAfterDelete.tsx";
import { ResourceWithIcon } from "../../components/ResourceWithIcon.tsx";
import { Spacer } from "../../components/Spacer.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableBillingAccountReadOnly } from "../../components/table/DetailsTableBillingAccount.tsx";
import { MonthlyCostRow } from "../../components/table/DetailsTableMonthlyCost.tsx";
import { DetailsTableName } from "../../components/table/DetailsTableName.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { ViewTitle } from "../../components/Title.tsx";
import { Toolbar } from "../../components/Toolbar.tsx";
import { useDeletableResource } from "../../utils/query/useDeletableResource.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../api/resourceTypeException.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { LocationDetail } from "../location/LocationDetail.tsx";
import { MetalDeleteModal } from "./MetalDeleteModal.tsx";
import { leaseDeleteMutation, leaseUpdateMutation, metalQueryAtom } from "./metalQuery.ts";
import { OsInfo } from "./os/OsInfo.tsx";
import { MetalStatusBadge } from "./status/MetalStatus.tsx";
import { getMetalStatusInfo } from "./status/StatusLabels.tsx";

function useDeleteMetalMutation() {
    return useStandardMutation(leaseDeleteMutation);
}

export type DeleteMetalMutation = ReturnType<typeof useDeleteMetalMutation>;

export function LeaseUserView({ bareId }: { bareId: string }) {
    //#region Hooks
    const data = useSuspenseQueryAtom(metalQueryAtom);
    const deleteMutation = useDeleteMetalMutation();
    const modifyMutation = useStandardMutation(leaseUpdateMutation);
    const metal = useDeletableResource(() => getResourceById(data, bareId, "bare_metal"), deleteMutation);
    //#endregion

    if (metal === undefined) {
        return <NavigateAfterDelete />;
    }

    const {
        uuid,
        display_name,
        location,
        mac_addresses,
        os_id,
        ip_public_v4,
        ip_public_v6,
        $type,
        created_at,
        billing_account_id,
        status,
    } = metal;

    // TODO: proper pricing
    const price = { hourly: 0.123 };

    async function onRename(newName: string) {
        newName = newName.trim();
        if (newName && metal?.display_name !== newName) {
            await modifyMutation.mutateAsync({
                body: { display_name: newName },
                uuid,
                location,
            });
        }
    }

    return (
        <>
            <Toolbar>
                <MetalDeleteModal metal={metal} deleteMutation={deleteMutation} />
            </Toolbar>

            <ContentPane>
                <ViewTitle title={display_name} />

                <DetailsHolder>
                    <DetailsTable>
                        <DetailsTableName
                            value={display_name}
                            isRequired
                            onChange={async (display_name) => await onRename(display_name)}
                        />

                        <DetailsTableRow title="Type:">
                            <ResourceWithIcon type={$type} />
                        </DetailsTableRow>
                        <DetailsTableRow title="OS:">
                            <OsInfo os_id={os_id} />
                        </DetailsTableRow>

                        <DetailsTableRow title="Status:" tooltip={getMetalStatusInfo(status).description}>
                            <MetalStatusBadge value={status} />
                        </DetailsTableRow>
                        <DetailsTableRow title="Public IPv4:">{ip_public_v4}</DetailsTableRow>
                        {ip_public_v6 != null && (
                            <DetailsTableRow title="Public IPv6:">
                                <ClipBoardTooltip isHtml>{ip_public_v6}</ClipBoardTooltip>
                            </DetailsTableRow>
                        )}
                    </DetailsTable>

                    <Spacer />

                    <DetailsTable>
                        <DetailsTableRow title="UUID:">
                            <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                        </DetailsTableRow>
                        <DetailsTableRow title="MAC:">{mac_addresses.toString()}</DetailsTableRow>

                        {!!location && <LocationDetail slug={location} />}

                        <DetailsTableRow title="Created:">
                            <LongDate date={created_at} />
                        </DetailsTableRow>
                        <DetailsTableRow />
                        <DetailsTableBillingAccountReadOnly billing_account_id={billing_account_id} />
                        <MonthlyCostRow price={price} />
                    </DetailsTable>
                </DetailsHolder>
            </ContentPane>
        </>
    );
}
